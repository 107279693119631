<template>
  <div
    class="components_task_side_bar_teach_pages_price_page bbox d-flex align-start "
  >
    <div class="d-flex flex-column align-center wrapper">
      <div class="title align-self-start">销售价格设置</div>
      <div class="price_group d-flex align-start flex-column">
        <div class="key flex-shrink-0 mb-10">设置价格：</div>
        <div class="price_box">
          <input type="text" class="price bbox"
            v-model="price"
            ref="input"
          >
          <div class="symbol">¥</div>
          <div class="hint"
            :class="{error: error}"
          >{{hint}}</div>
        </div>
      </div>
      <div class="intro_group d-flex align-start flex-column mb-20">
        <div class="name flex-shrink-0 mb-10">设置简介:</div>
        <el-input
          class="intro"
          type="textarea"
          :autosize="{ minRows: 5, maxRows: 5}"
          placeholder="输入简介内容"
          v-model="intro"
          resize="none"
        >
        </el-input>
      </div>
      <div class="notice">*设置、更改收益并上传教学文件后，系统将进行内容审核，审核通过后将自动发布，审核周期一般为1-2个工作日，请关注并耐心等待。</div>
      <div class="fun_group d-flex align-center justify-space-between">
        <div class="button btn_dark"
          :class="{disabled: !can_save}"
          @click="doSave"
        >保存</div>
        <div class="button btn_light"
          @click="doCancel"
        >取消</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data(){
    return {
      price: '',
      intro: '',
    }
  },
  watch:{
    //只能输入数字
    price: function(val){
      if(val){
        val = String(val);
        this.price = val.replace(/[^\d^]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.');
      }
    },
  },
  computed: {
    hint(){
      const {price} = this;
      let hint = '';
      // if(price > 50){
      //   hint = '售价不得高于¥50';
      // }else{
        if(price !== ''){
          hint = `个人实际收益：¥${Math.round(price * 0.7)}`
        }
      // }
      return hint;
    },
    error(){
      const {price} = this;
      // if(price > 50){
      //   return true;
      // }
      return false;
    },
    can_save(){
      const {price, error} = this;
      if(price !== '' && !error){
        return true;
      }
      return false;
    }
  },
  methods: {
    inputFocus(){
      this.$refs.input.focus();
    },
    doCancel(){
      const {task_type} = this.$store.state.task_config;
      if(task_type == 2){
        this.$emit('handleChangePage', 'make');
        return;
      }
      this.$emit('handleChangePage', 'start')
    },
    doSave(){
      const { can_save, price, intro } = this;
      if(!can_save) {
        return;
      }
      if(this.price === ''){
        return;
      }
      this.$store.commit('set_task_config', Object.assign(
        {},
        this.$store.state.task_config,
        {
          price       : price*100,
          introduction: intro,
        }

      ))
      this.$emit('handleChangePage', 'make');
    },
    init(){
      const { task_config } = this.$store.state
      const { price, introduction } = task_config
      if(price!== null && price !== ''){
        this.price = price/100;
      }
      this.intro = introduction ?? '';

    }
  },
  mounted(){
    this.init();
  }
}
</script>
<style lang="scss" scoped>
.components_task_side_bar_teach_pages_price_page{
  width: 100%;
  height: 100%;
  padding: 0 20px;
  .wrapper{
    width: 100%;
  }
  .title{
    font-size: 18px;
    font-weight: bold;
    color: #333333;
    line-height: 24px;
    padding-top: 40px;
  }
  .price_group{
    width: 100%;
    padding: {
      top: 60px;
      bottom: 60px;
    }
    .key{
      font-size: 16px;
      color: #333333;
      line-height: 21px;
      padding-right: 10px;
    }
    .price_box{
      flex:1;
      position: relative;
      .price{
        width: 100%;
        outline: none;
        background: #FBFBFB;
        border-radius: 4px;
        border: 1px solid #E0E0E0;
        position: relative;
        z-index: 2;
        font-size: 16px;
        font-family: MicrosoftYaHei;
        color: #333333;
        line-height: 38px;
        padding: {
          left: 36px;
        }
      }
      .symbol{
        position: absolute;
        z-index: 3;
        top: 0;
        left: 16px;
        font-size: 16px;
        color: #666666;
        line-height: 40px;
      }
      .hint{
        position: absolute;
        z-index: 3;
        left: 0;
        bottom: -30px;
        font-size: 14px;
        color: $main_blue;
        line-height: 20px;
        &.error{
          color: #D82E0D;
        }
      }
    }
  }
  .intro_group{
    width: 100%;
  }
  .notice{
    font-size: 14px;
    color: #666666;
    line-height: 19px;
    text-align: justify;
    text-align-last: left;
    text-justify: inter-ideograph;
  }
  .fun_group{
    width: 100%;
    padding-top: 80px;
    .button{
      font-size: 14px;
      line-height: 44px;
      border-radius: 4px;
      padding: 0 60px;
      &.disabled{
        background-color: #CCCCCC;
      }
    }
  }
}
</style>


<style lang="scss">
.components_task_side_bar_teach_pages_price_page{
  .intro{
    .el-textarea__inner{
      font-family     : "MicroSoft YaHei", "PingFang SC";
      background-color: #fbfbfb;
      border          : 1px solid #e0e0e0;
      font-size       : 14px;
      color           : #333;
      &:focus{
        border-color: #e0e0e0;
      }
    }
  }
}
</style>
