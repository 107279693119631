<script>
  import MakeTitle from '@/components/Task/SideBar/Teach/Pages/Make/Title.vue';
  import MakeFun from '@/components/Task/SideBar/Teach/Pages/Make/Fun.vue';
  import VideoPart from '@/components/Task/SideBar/Teach/Pages/Make/VideoPart.vue';
  import FilePart from '@/components/Task/SideBar/Teach/Pages/Make/FilePart.vue';
  import HyperlinkPart from '@/components/Task/SideBar/Teach/Pages/Make/HyperlinkPart.vue';
  import Consulting from '@/components/PopUp/Consulting.vue';
  import store from '@/store'
  import { mapState } from 'vuex'
  import request from '@/api/request'
  export default {
    components: {
      MakeTitle,
      MakeFun,
      VideoPart,
      FilePart,
      HyperlinkPart,
      Consulting,
    },
    props: {
      edit: {
        type: Boolean,
        default: false,
      },
      use: {
        type: Boolean,
        default: false,
      },
      preview: {
        type: Boolean,
        default: false,
      },
      maintain: {
        type: Boolean,
        default: false,
      },
      buy: {
        type: Boolean,
        default: false,
      },
      modify_mark: {
        type: Boolean,
        default: false,
      },
      sell: {
        type: Boolean,
        default: false,
      },
      show_intro: {
        type: Boolean,
        default: true,
      },
      shopping_mail: {
        type: Boolean,
        default: false,
      },
    },
    data(){
      return {
        uploading: false,
        switch_consulting: false,
        teachTypeList: [
          {
            value: 1,
            label: '标准课'
          },
          {
            value: 2,
            label: '实践课'
          },
          {
            value: 3,
            label: '专业群课'
          },
          {
            value: 4,
            label: '仿真'
          },
          {
            value: 5,
            label: '思政'
          },
          {
            value: 6,
            label: '双创融合'
          },
          {
            value: 7,
            label: '培训课'
          },
          {
            value: 8,
            label: '其他'
          },
        ],
        teachType: '',
        switchSelect: false,
        checkstatus: 2,
      }
    },
    computed: {
      ...mapState({
        // courseId: state => state.course_package.id,
        task_config: state => state.task_config,
        task: state => state.task,
        // coursePackageList: state => state.course_package.packageList,
      }),
      auditMode () {
        const { checkstatus } = this.task.task
        return store.state.task_config.auditMode && checkstatus === 2
      },
      teach_files(){
        return this.$store.state.teach_files;
      },
      fileList(){
        const {teach_files} = this;
        if (!teach_files) {
          return []
        }
        const {fileList} = teach_files;
        return fileList;
      },
      linkList(){
        const {teach_files} = this;
        if (!teach_files){
          return []
        }
        const {linkList} = teach_files;
        return linkList;
      },
      videoList(){
        const {teach_files} = this;
        if (!teach_files) {
          return []
        }
        const {videoList} = teach_files;
        return videoList;
      },
      submit_status(){
        const { edit, modify_mark, sell, task } = this
        if (task.task.taskType === 3) {
          return false
        }
        console.log({edit, modify_mark, sell})
        if (sell) {
          return false
        }
        return edit && modify_mark
      },
      canSaveDraft () {
        const { task } = this.$store.state
        const { draft, taskType } = task.task
        if (Number(taskType) === 2) {
          return Boolean(draft)
        }
        return true
      },
      maintain_status(){
        const { maintain, modify_mark, sell, auditMode } = this
        const cookieUser = this.$tools.getCookie('user')
        console.log({cookieUser})
        if (auditMode) {
          return false
        }
        if (cookieUser) {
          if (!cookieUser.sign) {
            return false
          }
        }
        if (sell) {
          return false
        }
        console.log(maintain, modify_mark, sell)
        return maintain && modify_mark ;
      },
      modify_status(){
        const {modify_mark, sell} = this;
        const {task_config,task} = this.$store.state;
        const userid = this.$tools.getUserId();
        const {signUserId} = task.task;
        const {name, params} = this.$route
        // console.log(this.$route)
        const {task_type, draft, view, call, share} = task_config;
        if(sell){
          return false;
        }
        // 自己是教学作者可以直接编辑
        // // console.log(task_type,signUserId , userid, draft )
        if(task_type == 2 ){
          if(signUserId == userid){
            if(!modify_mark){
              if(name === 'fun_template_my' && params && params.type === 'sell' ){
                return true
              }
            }
          }
        }
        return false;
      },
      cunsulting_status(){
        const {task_config,task} = this.$store.state;
        const {buy, sell} = this;
        const {task_type} = task_config;
        const {signUserId,price} = task.task;
        const userid = this.$tools.getUserId();
        // // console.log(buy);
        if(sell){
          return false;
        }
        if(task_type == 2 || task_type == 3){
          if(!buy && (price && price != 0) && !task.task.buy){
            if(signUserId != userid){
              return true;
            }
          }
        }
        return false;
      },
      param_id(){
        const {task} = this.$store.state;
        const {taskType, teachId, id} = task.task;
        if (taskType == 2) {
          return id;
        }
        return teachId;
      },
      introduction(){
        const {state} = this.$store;
        const {task_config} = state;
        const {introduction} = task_config;
        let intro = introduction ?? '';
        if(intro === ''){
          intro = '暂无简介';
        }
        return intro;
      },
      statusType () {
        const {
          checkstatus
        } = this
        if (checkstatus === 1) {
          return ''
        }
        if (checkstatus === 2) {
          return 'audit'
        }
        if (checkstatus === 3) {
          return 'approve'
        }
        if (checkstatus === 4) {
          return 'fail'
        }
        return ''
      },
      statusText () {
        const {
          checkstatus
        } = this
        if (checkstatus === 1) {
          return '未审核'
        }
        if (checkstatus === 2) {
          return '待审核'
        }
        if (checkstatus === 3) {
          return '审核通过'
        }
        if (checkstatus === 4) {
          return '审核不通过'
        }
        return ''
      },

    },
    mounted(){
      this.init()
    },
    methods: {
      doSelect (checkstatus) {
        this.switchSelect = false
        if (this.checkstatus === checkstatus) {
          return
        }
        this.checkstatus = checkstatus
        if (this.checkstatus === 4) {
          // 弹出原因
        }
      },
      changePage(status){
        this.$emit('handleChangePage', status)
      },
      touchAdd(type){
        this.$refs[type].touchAdd();
      },
      addVideo(list){
        // console.log(list);
        const {videoList, teach_files} = this;
        if(list.length){
          this.$store.commit('set_teach_files', Object.assign(
            {},
            teach_files,
            {videoList: videoList.concat(list)}
          ))
          this.$nextTick(function(){
            this.autoUpload('videoList');
          })

        }
      },
      addFile(list){
        const {fileList, teach_files} = this;
        if(list.length){
          this.$store.commit('set_teach_files', Object.assign(
            {},
            teach_files,
            {fileList: fileList.concat(list)}
          ))
          this.$nextTick(function(){
            this.autoUpload('fileList');
          })

        }
      },
      addHyperlink(data){
        const {linkList, teach_files} = this;
        this.$store.commit('set_teach_files', Object.assign(
          {},
          teach_files,
          {linkList: linkList.concat(data)}
        ))
      },
      editHyperlink(data){
        const {edit, item} = data;
        this.$set(item, 'content', edit.content);
        this.$set(item, 'edit', false);
        this.$set(item, 'modified', true);

      },
      deleteHyperlink(item){
        const {linkList} = this;
        const index = linkList.findIndex(unit=> {
          if(item.id != undefined){
            return unit.id == item.id;
          }
          if(item.custom_index != undefined){
            return item.custom_index == unit.custom_index;
          }
        })
        if(index != -1){
          linkList.splice(index, 1);
        }
      },
      // 自动查找第一个可上传文件上传
      async autoUpload(type){
        //标记控件修改
        this.uploading = true; // 正在上传
        this.$store.commit('set_task_config', Object.assign(
          {},
          this.$store.state.task_config,
          {[`uploading_teach_${type}`]:true}
        ))
        const list = this[type];
        const file = list.find(unit=>{
          return !unit.file.url && unit.file.status !== 'failed';
        })
        if(file !== undefined){
          let res = await this.upload(file.file);
          if(res){
            // this.$set(file, 'status', 'done');
            this.$set(file.file, 'url', res);
            this.$set(file.file, 'createTime', new Date().getTime());
            this.$set(file.file, 'status', '');
          }else{
            this.$set(file.file, 'status', 'failed');
          }
          this.autoUpload(type);
        }else{
          this.uploading = false; // 正在上传 结束
          this.$store.commit('set_task_config', Object.assign(
            {},
            this.$store.state.task_config,
            {[`uploading_teach_${type}`]:false}
          ))
          return;
        }

      },
      async reUpload(params){
        const {type, item} = params;
        const {file} = item;
        const {uploading} = this;
        if(uploading){
          this.$notice({desc: '有文件正在上传, 请稍候再试!'});
          return;
        }
        this.uploading = true; // 正在上传
        // this.$set(this.item, 'uploading', true); // 正在上传
        // const list = this[type];
        this.$set(file, 'status', '');
        let res = await this.upload(file);
        if(res){
          // this.$set(file, 'status', 'done');
          this.$set(file, 'url', res);
          this.$set(file, 'createTime', new Date().getTime());
          this.$set(file, 'status', '');
        }else{
          this.$set(file, 'status', 'failed');
        }

        this.uploading = false; // 正在上传 结束

      },
      async upload(file){
        // console.log(file);
        let res;
        const handleProgress = (progress)=>{
          this.$set(file, 'progress', Math.floor(progress*100));
        }
        res = await this.$api.oss.uploadFile({
          file: file.file,
          handleProgress
        })
        if(res !== false){
          return res;
        }
        return res;
      },
      doSend(){
        // console.log(this.teachType)
        this.task_config.task_type = 2
        this.task_config.teachType = this.teachType
        // return
        this.$emit('handleSubmit');
      },
      saveDraft(){
        this.$emit('handleSaveDraft');
      },
      async init(){
        console.log('init')
        const { $route } = this
        const { name } = $route
        let url = '/projectTeach/fileList'
        console.log(name)
        if (name === 'check_task') {
          url = '/projectOfficialWebsite/fileList'
        }
        const { task_config } = this.$store.state
        const { teach, teach_id, teachType } = task_config
        console.log(
          this.$store.state.task_config,
          this.$store.state.task
        )
        this.teachType = this.$store.state.task.task.teachType
        // console.log({teach, teach_id})
        // const {linkList, videoList, fileList} = this;
        if (teach_id && teach) {
          const res = await this.$global.doPost({
            url,
            data: {teachId: teach_id},
            openNoitce: false,
          })
          if (res) {
            this.$store.commit('set_teach_files', res.contents);
          }
        }
      },
      /** TODO 购买(模拟) */
      async doBuy(){
        let {task, task_config} = this.$store.state;
        let {price, teachId,teach_id, id, taskType} = task.task;
        let data_id;
        if(taskType == 3){
          data_id = teachId || teach_id;
        }
        if(taskType == 2){
          data_id = id;
        }
        let data = {
          id: data_id,
          price: price*100,
          type: 1,
        }
        let res = await this.$http.request('/projectTeach/buy',data);
        if(res.message == 'success'){
          // this.task_data.buy = true;
          // this.$set(this.task_data, 'buy', true);
          this.$store.commit('set_task', Object.assign(
            {},
            task,
            {
              task: {
                buy: true,
              }
            }
          ))
          // this.$toast({icon:'none',title:'提示',desc:'购买成功',duration:1000});
          this.$notice({desc: '购买成功', type: 'success'});
        }else{
          // this.$toast({icon:'none',title:'提示',desc:'购买失败',duration:1000});
          this.$notice({desc: '购买失败'});
        }
      },
      reEdit(){
        this.$emit('handleReEdit', true);
      },
      // 前往维护
      doMaintain(){
        // this.$store.commit('set_switch_task_box', false);
        // this.$router.push({name: 'fun_template_my', params:{type: 'sell'}});
        let moderated_task = false;
        let {task, task_config} = this.$store.state;
        const {teachId} = task.task;
        const {task_id, task_type} = task_config;
        const {taskDeatails} = task;
        const id = task_type == 3 ? teachId : task_id;
        if(taskDeatails){
          taskDeatails.forEach(item=>{
            if(item.modified){
              moderated_task = true;
            }
            if(!moderated_task){
              if(item.controlLinkList && item.controlLinkList.length){
                item.controlLinkList.forEach(unit=> {
                  if(unit.modified){
                    moderated_task  = true;
                  }
                })
              }
            }
          })
        }
        // // console.log(moderated_task, task_details, '前往维护数据=')
        if(moderated_task){
          this.$openConfirm({
            title:'提示',
            desc:'当前模版内容有变更，跳转前是否发布模版？',
            sure:'发布',
            cancel:'不发布',
            onSure: async ()=> {
              this.$emit('handleSavaTask');
              this.$router.push({name: 'fun_template_my', params:{type: 'sell'}});
              this.$api.moduleTask.openTask({taskId:id ?? this.$store.state.task_original.task.id, config:{
                  edit:true,
                }});
            },
            onCancel: () => {
              this.$api.moduleTask.closeTask();
              this.$router.push({name: 'fun_template_my', params:{type: 'sell'}});
              this.$api.moduleTask.openTask({
                taskId:id ?? this.$store.state.task_original.task.id ,
                config:{
                  edit:true,
                }
              });
            }
          })
        }else{
          this.$store.commit('set_task_config', Object.assign(
            {},
            this.$store.state.task_config,
            {
              open_by_call: false, // 通过调用打开, 如果关闭时modified为false 则返回新建页
              open_with_call_page: false,
            }
          ))
          this.$api.moduleTask.closeTask();
          this.$router.push({name: 'fun_template_my', params:{type: 'sell'}});
          this.$api.moduleTask.openTask({taskId:id ?? this.$store.state.task_original.task.id, config:{
              edit:true,
              open_by_call: false, // 通过调用打开, 如果关闭时modified为false 则返回新建页
              open_with_call_page: false,
            }});
          return;
        }
      },

      // 咨询
      touchConsulting(){
        const {state} = store
        const {task} = state
        if(task && task.task.cancelflag){
          this.$notice({desc: '作者已停止更新'})
          return
        }
        this.switch_consulting = true;
      },
      closeConsulting(){
        this.switch_consulting = false;
      },
      changeTeachType (e) {
        // console.log(e, this.teachType)
        this.task_config.teachType = this.teachType
        console.log(this.task_config)
      },
      async doAudit () {
        console.log(this.task)
        console.log(this.checkstatus)
        if (this.checkstatus === 3) {
          // 成功
          // 任务 未通过
          const res = await request.request(
            '/resource/checkCource',
            {
              type: 2, // 任务
              id: store.state.task.task.id,
              checkStatus: 3,
              reason: ''
            }
          )
          if (res.message === 'success') {
            this.$notice({
              desc: '提交成功',
              type: 'success'
            })
            this.$api.moduleTask.closeTask()
          }
          return
        }
        if (this.checkstatus === 4) {
          // 失败弹窗
          store.commit(
            'setTaskInfo',
            store.state.task.task
          ) // 设置任务信息
          this.$modal.show('globalCoursePackageFailReason', {
            width: '1000px'
          })
          return
        }
        this.$notice({
          desc: '请选择审核状态',
          type: 'error'
        })
      }
    }

  }
</script>


<template>
  <div
    class="components_task_side_bar_teach_pages_make_page bbox d-flex flex-column align-start"
  >
    <div class="main">
      <make-title
        @handleChangePage="changePage"
        :edit="edit"
        :use="use"
        :preview="preview"
      ></make-title>
      <make-fun
        class="make_fun"
        title="简介"
        type="intro"
        :edit="edit"
        :use="use"
        :preview="preview"
        :open_add="false"
        v-if="show_intro"
      ></make-fun>
      <div
        v-if="show_intro"
        class="intro_group bbox pa-10"
      >
        <div
          class="intro"
        >
          {{ introduction }}
        </div>
      </div>
      <make-fun
        class="make_fun"
        title="类型"
        type="intro"
        :edit="edit"
        :use="use"
        :preview="preview"
        :open_add="false"
        v-if="show_intro"
      ></make-fun>
      <!-- 教学类型 -->
      <el-select
        class="mt-10 un_sel selete_teach_type_group"
        v-model="teachType"
        placeholder="请选择教学类型"
        size="small"
        @change="changeTeachType"
      >
        <el-option
          v-for="item in teachTypeList"
          :key="item.value"
          :label="item.label"
          :value="item.value">
        </el-option>
      </el-select>
      <make-fun
        class="make_fun"
        title="视频"
        type="video"
        @handleClick="touchAdd"
        :edit="edit"
        :use="use"
        :preview="preview"
      ></make-fun>
      <video-part
        class="part_group"
        ref="video"
        :list="videoList"
        :uploading="uploading"
        @handleAdd="addVideo"
        :edit="edit"
        :use="use"
        :preview="preview"
        @handleReUpload="reUpload"
      ></video-part>
      <make-fun
        class="make_fun"
        title="文件"
        type="file"
        @handleClick="touchAdd"
        :edit="edit"
        :use="use"
        :preview="preview"
      ></make-fun>
      <file-part
        class="part_group"
        ref="file"
        :list="fileList"
        :uploading="uploading"
        @handleAdd="addFile"
        :edit="edit"
        :use="use"
        :preview="preview"
        @handleReUpload="reUpload"
      ></file-part>
      <make-fun
        class="make_fun"
        title="链接"
        type="hyperlink"
        @handleClick="touchAdd"
        :edit="edit"
        :use="use"
        :preview="preview"
      ></make-fun>
      <hyperlink-part
        class="part_group"
        ref="hyperlink"
        :list="linkList"
        @handleAdd="addHyperlink"
        @handleEdit="editHyperlink"
        @handleDelete="deleteHyperlink"
        :edit="edit"
        :use="use"
        :sell="sell"
        :preview="preview"
      ></hyperlink-part>
    </div>
    <template
      v-if="!shopping_mail"
    >
      <div
        v-if="submit_status"
        class="fun_group d-flex align-center justify-space-between flex-shrink-0"
      >
        <div
          class="button btn_dark"
          :class="{
            big_button: !canSaveDraft
          }"
          @click="doSend"
        >
          提交审核
        </div>
        <div
          v-if="canSaveDraft"
          class="button btn_light"
          @click="saveDraft();"
        >保存草稿</div>
      </div>
      <div
        class="fun_group"
        v-if="cunsulting_status"
      >
        <div
          class="button cunsulting fulid"
          @click="touchConsulting();"
        >咨询</div>
      </div>
      <div
        v-if="maintain_status"
        class="fun_group"
      >
        <div
          class="button btn_dark fulid"
          @click="doMaintain();"
        >
          前往维护
        </div>
      </div>
      <div
        class="fun_group"
        v-if="buy && !auditMode"
      >
        <div
          class="button btn_dark fulid"
          @click="doBuy()"
        >免费领取</div>
      </div>
      <div
        class="fun_group"
        v-if="modify_status"
      >
        <div class="button btn_dark fulid"
             @click="reEdit();"
        >重新编辑</div>
      </div>
    </template>
    <template
      v-if="auditMode"
    >
      <div
        class="audit_fun_group d-flex align-center justify-space-between bbox_wrapper"
      >
        <el-popover
          v-model="switchSelect"
          placement="top"
          trigger="click"
        >
          <div
            class="c_rl_teaching_task_teaching_area_make_check_select_list_group"
          >
            <div
              class="select_item audit"
              @click.stop="doSelect(2)"
            >
              <div
                class="icon"
              ></div>
              <div
                class="text"
              >
                待审核
              </div>
            </div>
            <div
              class="select_item approve"
              @click.stop="doSelect(3)"
            >
              <div
                class="icon"
              ></div>
              <div
                class="text"
              >
                通过
              </div>
            </div>
            <div
              class="select_item fail"
              @click.stop="doSelect(4)"
            >
              <div
                class="icon"
              ></div>
              <div
                class="text"
              >
                未通过
              </div>
            </div>
          </div>
          <template
            slot="reference"
          >
            <div
              class="select_btn_group d-flex align-center justify-center cp"
              :class="{
                [statusType]: true,
                on: switchSelect
              }"
            >
              <div
                class="icon"
              ></div>
              <div
                class="text"
              >
                {{ statusText }}
              </div>
              <div
                class="symbol"
              ></div>
            </div>
          </template>

        </el-popover>
        <div
          class="btn btn_dark"
          @click.stop="doAudit"
        >
          提交
        </div>
      </div>
    </template>
    <consulting
      v-if="switch_consulting"
      :param_id="Number(param_id)"
      :enquire_type="1"
      @handleClose="closeConsulting"
    ></consulting>
  </div>
</template>


<style lang="scss" scoped>
.components_task_side_bar_teach_pages_make_page{
  width: 100%;
  height: 100%;
  overflow: auto;
  .main{
    @include scrollbar;
    overflow-y:auto;
    flex:1;
    width: 100%;
  }
  .make_fun{
    margin-top: 20px;
  }
  .part_group{
    padding-top: 10px;
  }
  .fun_group{
    width: 100%;
    padding-top: 10px;
    .button{
      box-sizing: border-box;
      font-size: 14px;
      line-height: 44px;
      border-radius: 4px;
      padding: 0 60px;
      text-align: center;
      &.big_button {
        flex: 1;
      }
      &.disabled{
        background-color: #CCCCCC;
      }
      &.fulid{
        width: 100%;
        text-align: center;
      }
      &.cunsulting{
        background-color: #2BB06C;
        color: #fff;
      }
    }
  }
  .audit_fun_group {
    width: 100%;
    padding-top: 10px;
    .btn {
      box-sizing: border-box;
      font-size: 14px;
      line-height: 44px;
      border-radius: 4px;
      padding: 0 60px;
      text-align: center;
    }
    .select_btn_group {
      width: 170px;
      height: 44px;
      border-radius: 4px;
      border: 1px solid #F78900;
      position: relative;
      .icon {
        width: 16px;
        height: 16px;
        margin-right: 10px;
      }
      .text {
        font-size: 14px;
        color: #333;
        line-height: 21px;
        text-align: left;
        font-style: normal;
        text-transform: uppercase;
      }
      .symbol {
        @include background(11px 6px);
        position: absolute;
        z-index: 9;
        top: 50%;
        right: 20px;
        transform: translateY(-50%);
        width: 20px;
        height: 20px;

      }
      &.fail {
        border-color: #D82E0D;
        color: #D82E0D;
        .text {
          color: #D82E0D;
        }
        .icon {
          background-image: url(~@/assets/images/course_package/select_fail.png);
        }
        .symbol {
          background-image: url(~@/assets/images/course_package/fail_open.png);
        }
      }
      &.audit {
        border-color: #F78900;
        color: #F78900;
        .text {
          color: #F78900;
        }
        .icon {
          background-image: url(~@/assets/images/course_package/select_audit.png);
        }
        .symbol {
          background-image: url(~@/assets/images/course_package/audit_open.png);
        }
      }
      &.approve {
        border-color: #05AD00;
        color: #05AD00;
        .text {
          color: #05AD00;
        }
        .icon {
          background-image: url(~@/assets/images/course_package/select_approve.png);
        }
        .symbol {
          background-image: url(~@/assets/images/course_package/approve_open.png);
        }
      }
      &.on {
        &.fail {
          .symbol {
            background-image: url(~@/assets/images/course_package/fail_close.png);
          }
        }
        &.audit {
          .symbol {
            background-image: url(~@/assets/images/course_package/audit_close.png);
          }
        }
        &.approve {
          .symbol {
            background-image: url(~@/assets/images/course_package/approve_close.png);
          }
        }

      }
    }
  }
  .selete_teach_type_group {
    width: 100%;
  }
  .intro_group{
    width: 100%;
    .intro{
      word-break: break-all;
      white-space: pre-wrap;
      word-wrap: break-word;
      font-size: 14px;
      color: #333333;
      line-height: 20px;
    }
  }
}
</style>
<style lang="scss">
.c_rl_teaching_task_teaching_area_make_check_select_list_group {
  .select_item {
    cursor: pointer;
    @include transition;
    width: 170px;
    height: 44px;
    border-radius: 10px;
    //border: 1px solid #ccc;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 5px 0;
    &:hover {
      .icon {
        opacity: 1;
      }
      .text {
        margin-left: 0;
      }
      &.fail {
        background-color: #D82E0D;
        .icon {
          background-image: url(~@/assets/images/course_package/select_fail_on.png);
        }
        .text {
          color: #fff;
        }
      }
      &.audit {
        background-color: #F78900;
        .icon {
          background-image: url(~@/assets/images/course_package/select_audit_on.png);
        }
        .text {
          color: #fff;
        }
      }
      &.approve {
        background-color: #05AD00;
        .icon {
          background-image: url(~@/assets/images/course_package/select_approve_on.png);
        }
        .text {
          color: #fff;
        }
      }

    }
    &.fail {

      //color: #D82E0D;
      //.text {
      //  color: #D82E0D;
      //}
      .icon {
        background-image: url(~@/assets/images/course_package/select_fail.png);
      }
      .symbol {
        background-image: url(~@/assets/images/course_package/fail_open.png);
      }
    }
    &.audit {

      //color: #F78900;
      //.text {
      //  color: #F78900;
      //}
      .icon {
        background-image: url(~@/assets/images/course_package/select_audit.png);
      }
      .symbol {
        background-image: url(~@/assets/images/course_package/audit_open.png);
      }
    }
    &.approve {

      //color: #05AD00;
      //.text {
      //  color: #05AD00;
      //}
      .icon {
        background-image: url(~@/assets/images/course_package/select_approve.png);
      }
      .symbol {
        background-image: url(~@/assets/images/course_package/approve_open.png);
      }
    }
    .icon {
      @include transition;
      opacity: 0;
      width: 16px;
      height: 16px;
      margin-right: 10px;
    }
    .text {
      @include transition;
      margin-left: -26px;
      font-size: 14px;
      color: #333;
      line-height: 21px;
      text-align: left;
      font-style: normal;
      text-transform: uppercase;
    }
  }
}
</style>
