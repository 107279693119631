var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "components_task_side_bar_teach_pages_make_page bbox d-flex flex-column align-start",
    },
    [
      _c(
        "div",
        { staticClass: "main" },
        [
          _c("make-title", {
            attrs: { edit: _vm.edit, use: _vm.use, preview: _vm.preview },
            on: { handleChangePage: _vm.changePage },
          }),
          _vm.show_intro
            ? _c("make-fun", {
                staticClass: "make_fun",
                attrs: {
                  title: "简介",
                  type: "intro",
                  edit: _vm.edit,
                  use: _vm.use,
                  preview: _vm.preview,
                  open_add: false,
                },
              })
            : _vm._e(),
          _vm.show_intro
            ? _c("div", { staticClass: "intro_group bbox pa-10" }, [
                _c("div", { staticClass: "intro" }, [
                  _vm._v("\n        " + _vm._s(_vm.introduction) + "\n      "),
                ]),
              ])
            : _vm._e(),
          _vm.show_intro
            ? _c("make-fun", {
                staticClass: "make_fun",
                attrs: {
                  title: "类型",
                  type: "intro",
                  edit: _vm.edit,
                  use: _vm.use,
                  preview: _vm.preview,
                  open_add: false,
                },
              })
            : _vm._e(),
          _c(
            "el-select",
            {
              staticClass: "mt-10 un_sel selete_teach_type_group",
              attrs: { placeholder: "请选择教学类型", size: "small" },
              on: { change: _vm.changeTeachType },
              model: {
                value: _vm.teachType,
                callback: function ($$v) {
                  _vm.teachType = $$v
                },
                expression: "teachType",
              },
            },
            _vm._l(_vm.teachTypeList, function (item) {
              return _c("el-option", {
                key: item.value,
                attrs: { label: item.label, value: item.value },
              })
            }),
            1
          ),
          _c("make-fun", {
            staticClass: "make_fun",
            attrs: {
              title: "视频",
              type: "video",
              edit: _vm.edit,
              use: _vm.use,
              preview: _vm.preview,
            },
            on: { handleClick: _vm.touchAdd },
          }),
          _c("video-part", {
            ref: "video",
            staticClass: "part_group",
            attrs: {
              list: _vm.videoList,
              uploading: _vm.uploading,
              edit: _vm.edit,
              use: _vm.use,
              preview: _vm.preview,
            },
            on: { handleAdd: _vm.addVideo, handleReUpload: _vm.reUpload },
          }),
          _c("make-fun", {
            staticClass: "make_fun",
            attrs: {
              title: "文件",
              type: "file",
              edit: _vm.edit,
              use: _vm.use,
              preview: _vm.preview,
            },
            on: { handleClick: _vm.touchAdd },
          }),
          _c("file-part", {
            ref: "file",
            staticClass: "part_group",
            attrs: {
              list: _vm.fileList,
              uploading: _vm.uploading,
              edit: _vm.edit,
              use: _vm.use,
              preview: _vm.preview,
            },
            on: { handleAdd: _vm.addFile, handleReUpload: _vm.reUpload },
          }),
          _c("make-fun", {
            staticClass: "make_fun",
            attrs: {
              title: "链接",
              type: "hyperlink",
              edit: _vm.edit,
              use: _vm.use,
              preview: _vm.preview,
            },
            on: { handleClick: _vm.touchAdd },
          }),
          _c("hyperlink-part", {
            ref: "hyperlink",
            staticClass: "part_group",
            attrs: {
              list: _vm.linkList,
              edit: _vm.edit,
              use: _vm.use,
              sell: _vm.sell,
              preview: _vm.preview,
            },
            on: {
              handleAdd: _vm.addHyperlink,
              handleEdit: _vm.editHyperlink,
              handleDelete: _vm.deleteHyperlink,
            },
          }),
        ],
        1
      ),
      !_vm.shopping_mail
        ? [
            _vm.submit_status
              ? _c(
                  "div",
                  {
                    staticClass:
                      "fun_group d-flex align-center justify-space-between flex-shrink-0",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "button btn_dark",
                        class: {
                          big_button: !_vm.canSaveDraft,
                        },
                        on: { click: _vm.doSend },
                      },
                      [_vm._v("\n        提交审核\n      ")]
                    ),
                    _vm.canSaveDraft
                      ? _c(
                          "div",
                          {
                            staticClass: "button btn_light",
                            on: {
                              click: function ($event) {
                                return _vm.saveDraft()
                              },
                            },
                          },
                          [_vm._v("保存草稿")]
                        )
                      : _vm._e(),
                  ]
                )
              : _vm._e(),
            _vm.cunsulting_status
              ? _c("div", { staticClass: "fun_group" }, [
                  _c(
                    "div",
                    {
                      staticClass: "button cunsulting fulid",
                      on: {
                        click: function ($event) {
                          return _vm.touchConsulting()
                        },
                      },
                    },
                    [_vm._v("咨询")]
                  ),
                ])
              : _vm._e(),
            _vm.maintain_status
              ? _c("div", { staticClass: "fun_group" }, [
                  _c(
                    "div",
                    {
                      staticClass: "button btn_dark fulid",
                      on: {
                        click: function ($event) {
                          return _vm.doMaintain()
                        },
                      },
                    },
                    [_vm._v("\n        前往维护\n      ")]
                  ),
                ])
              : _vm._e(),
            _vm.buy && !_vm.auditMode
              ? _c("div", { staticClass: "fun_group" }, [
                  _c(
                    "div",
                    {
                      staticClass: "button btn_dark fulid",
                      on: {
                        click: function ($event) {
                          return _vm.doBuy()
                        },
                      },
                    },
                    [_vm._v("免费领取")]
                  ),
                ])
              : _vm._e(),
            _vm.modify_status
              ? _c("div", { staticClass: "fun_group" }, [
                  _c(
                    "div",
                    {
                      staticClass: "button btn_dark fulid",
                      on: {
                        click: function ($event) {
                          return _vm.reEdit()
                        },
                      },
                    },
                    [_vm._v("重新编辑")]
                  ),
                ])
              : _vm._e(),
          ]
        : _vm._e(),
      _vm.auditMode
        ? [
            _c(
              "div",
              {
                staticClass:
                  "audit_fun_group d-flex align-center justify-space-between bbox_wrapper",
              },
              [
                _c(
                  "el-popover",
                  {
                    attrs: { placement: "top", trigger: "click" },
                    model: {
                      value: _vm.switchSelect,
                      callback: function ($$v) {
                        _vm.switchSelect = $$v
                      },
                      expression: "switchSelect",
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "c_rl_teaching_task_teaching_area_make_check_select_list_group",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "select_item audit",
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.doSelect(2)
                              },
                            },
                          },
                          [
                            _c("div", { staticClass: "icon" }),
                            _c("div", { staticClass: "text" }, [
                              _vm._v("\n              待审核\n            "),
                            ]),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "select_item approve",
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.doSelect(3)
                              },
                            },
                          },
                          [
                            _c("div", { staticClass: "icon" }),
                            _c("div", { staticClass: "text" }, [
                              _vm._v("\n              通过\n            "),
                            ]),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "select_item fail",
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.doSelect(4)
                              },
                            },
                          },
                          [
                            _c("div", { staticClass: "icon" }),
                            _c("div", { staticClass: "text" }, [
                              _vm._v("\n              未通过\n            "),
                            ]),
                          ]
                        ),
                      ]
                    ),
                    _c("template", { slot: "reference" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "select_btn_group d-flex align-center justify-center cp",
                          class: {
                            [_vm.statusType]: true,
                            on: _vm.switchSelect,
                          },
                        },
                        [
                          _c("div", { staticClass: "icon" }),
                          _c("div", { staticClass: "text" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.statusText) +
                                "\n            "
                            ),
                          ]),
                          _c("div", { staticClass: "symbol" }),
                        ]
                      ),
                    ]),
                  ],
                  2
                ),
                _c(
                  "div",
                  {
                    staticClass: "btn btn_dark",
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.doAudit.apply(null, arguments)
                      },
                    },
                  },
                  [_vm._v("\n        提交\n      ")]
                ),
              ],
              1
            ),
          ]
        : _vm._e(),
      _vm.switch_consulting
        ? _c("consulting", {
            attrs: { param_id: Number(_vm.param_id), enquire_type: 1 },
            on: { handleClose: _vm.closeConsulting },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }